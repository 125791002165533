import  React from 'react';
import { Link } from 'gatsby';
import {Link as GoLink} from 'react-scroll/modules';
import Layout from '../components/layout';
import Seo from '../components/seo';
import ListBrand from '../components/list-brand';
import ListLink from '../components/list-link';
import Accordion from '../components/Accordion';
import Image from '../components/Image';

const ShowroomPage = () => (
    <Layout page="showroom-page">
      <Seo title="SHOWROOM"/>
      <section className="page-head">
        <ListBrand/>
        <ListLink/>
        <h2 className="main-title"><span>SHOWROOM</span></h2>
      </section>
      <section className="page-content flex-layout">
        <div className="main-content">
          <div className="content-block">
            <h3 className="sub-title"><span>ショールームのご案内</span></h3>
            <p>デンマーク王室も御用達、創業160年以上の歴史あるブランド「morso」、工業大国ドイツ内でも優れた生産技術を誇る「LEDA」、2種類のブランドの日本総輸入元である新宮商行のショールームです。</p>
            <p>クラシックデザインやヨーロッパらしいスタイリッシュでモダンなデザインの薪ストーブまで幅広くラインナップをしています。</p>
          </div>
          <div className="content-block" id="matudo">
            <h3 className="sub-title">MATSUDO</h3>
            <p className="c-yellow md:text-3xl">松戸ショールーム</p>
            <div className="md:flex mt-9">
              <div className="md:w-1/2">
                <Image className="full-image" filename="showroom/img1.jpg" />
              </div>
              <div className="md:w-1/2">
                <Image className="full-image" filename="showroom/img2.jpg" />
              </div>
            </div>
            <div>
              <p className="mt-8">ショールームは業界最大級の広さ（約170㎡）を有し『お客様に実際の炎をゆったりと体験していただける空間』をコンセプトにしています。常時6台のストーブを焚くことができ、それぞれのストーブの持つ魅力や炎の雰囲気を実際に見て、その暖かさを肌で感じていただくことができます。</p>
              <p className="mt-8">薪ストーブの他にも、関連商品の屋外用ピザオーブンmorsoliving、長年の歴史を有するオーストリアMüller製の斧、薪作りの為の必需品・シングウ薪割機など、アウトドア関連用品もございます。お気軽にお問い合わせください。</p>
              <p className="mt-8">尚スムーズにご案内できるよう予約制とさせて頂いております。ご来場時は予約フォーム・お電話にてご予約をお願いいたします。</p>
            </div>
            <div className="access mt-12">
              <h4 className="yu text-2xl c-gray">アクセス</h4>
              <div className="map md:flex mt-12">
                <div className="map-r md:w-1/2">
                  <div className="panel-showroom md:w-full">
                    <p className="name">松戸ショールーム</p>
                    <p>〒270-2231 千葉県松戸市稔台6-7-5<br />
                      TEL:047-361-3800   FAX:047-362-0157<br />
                      営業時間:10：00～17：00（要ご予約）<br />
                      定休日:土曜日、日曜日、祝日<br />
                      ※第2土曜日のみ1週間前までの事前予約にて対応します。</p>
                  </div>
                  <div className="map-l mt-10">
                    <div className="gmap">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1618.3322732298193!2d139.92175!3d35.7836157!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x601884624c140877%3A0x12a9410da188755!2z44CSMjcwLTIyMzEg5Y2D6JGJ55yM5p2-5oi45biC56iU5Y-w77yW5LiB55uu77yX4oiS77yVIOaWsOWuruWVhuihjA!5e0!3m2!1sja!2sjp!4v1653383358094!5m2!1sja!2sjp" width="600" height="450" allowFullScreen="" loading="lazy" title="shingu" />
                    </div>
                  </div>
                </div>
                <div className="figure-wrap md:w-1/2">
                  <div className="w-full figure">
                    <Image className="full-image w-1/2" filename="showroom/figure-matudo.png" />
                  </div>
                </div>
              </div>
              <div className="md:mt-32 mt-6">
                <h5 className="yu text-2xl c-gray">電車でお越しになられる方</h5>
                <p className="md:mt-8 mt-4">
                  ①JR常磐線「松戸」駅東口バス停より<br />
                  松戸新京成バス「東松戸駅行き（工業団地経由）」乗車。所要約15分「ウィズタウン前」バス停にて下車。徒歩約5分。
                </p>
                <p className="md:mt-8 mt-4">②新京成電鉄「みのり台」駅より徒歩15分</p>
              </div>
              <div className="mt-12">
                <h5 className="yu text-2xl c-gray">お車でお越しになられる方</h5>
                <p className="md:mt-8 mt-4">※カーナビでは弊社入口の登録ができず入口のない裏手に登録される場合がございますので下記ご確認ください。</p>
                <p className="md:mt-8 mt-4">
                  【  東京方面からお越しの方  】<br />
                  首都高速中央環状線「四ツ木」出口から約10.5km<br />
                  四ツ木」出口を出て国道6号線を直進（約9km）<br />
                  ⇒ 七畝割（交差点） を右折して 県道281号線 に入り直進。（約1.5km）<br />
                  ⇒ 澁澤倉庫とMary's工場の間、新宮商行の看板を右折。奥まで直進（約150m）
                </p>
                <p className="md:mt-8 mt-4">
                  【  千葉方面からお越しの方  】<br />
                  京葉道路「原木IC」より約11㎞ 「原木IC」を出て右折、県道179号に入り（松戸・西船橋の表示）直進。<br />
                  ⇒左折して県道180号線に入り直進。（約8.2km）<br />
                  ⇒梨百台ゴルフガーデンを過ぎて次の信号（交差点） を右折。<br />
                  ⇒ 国道464号線を過ぎてしばらく直進、Ｔ字路を左折。<br />
                  ⇒しばらく道なりに進み、和名ヶ谷ひまわり幼稚園の所を右折。<br />
                  ⇒その後、右斜めに入り、2本目を左折。国道281号へ向かう。<br />
                  ⇒国道281号を右折。最後に澁澤倉庫とMary's工場の間、新宮商行の看板を右折。奥まで直進（約150m）
                </p>
                <div className="md:mt-8 mt-4">
                  <div className="md:flex">
                    <div className="md:w-1/2">
                      <Image className="full-image" filename="showroom/access-01.jpg" />
                      <p>【 右折時 】<br />Mary's様の手前を右折してください。低い位置に弊社看板がございます。</p>
                    </div>
                    <div className="md:w-1/2 md:mt-0 mt-6">
                      <Image className="full-image" filename="showroom/access-02.jpg" />
                      <p>【 左折時 】<br />澁澤倉庫様の手前を左折してください。低い位置に弊社看板がございます。</p>
                    </div>
                  </div>
                  <div className="md:flex mt-6">
                    <div className="md:w-1/2">
                      <Image className="full-image" filename="showroom/access-03.jpg" />
                      <p>【 弊社手前の道 】<br />澁澤倉庫様の前を通り過ぎて突当りが弊社になります。</p>
                    </div>
                    <div className="md:w-1/2 md:mt-0 mt-6">
                      <Image className="full-image" filename="showroom/access-04.jpg" />
                      <p>【 ショールーム入口 】<br />門入って右手にショールーム入口がございます。<br />ショールームは２階になりますが玄関にあります内線で担当者をお呼びください。</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-block" id="sapporo">
            <h3 className="sub-title">SAPPORO</h3>
            <p className="c-yellow md:text-3xl">アンデルセンストーブ 北海道ショールーム</p>
            <div className="md:flex mt-9">
              <div className="md:w-1/2">
                <Image className="full-image" filename="showroom/img3.jpg" />
              </div>
              <div className="md:w-1/2">
                <Image className="full-image" filename="showroom/img4.jpg" />
              </div>
            </div>
            <div>
              <p className="mt-8">日本総輸入元となっている新宮商行で取り扱う薪ストーブのトータルブランド、アンデルセンストーブのブランドショールーム。クラシックなデザインから北欧らしいスタイリッシュでモダンなデザインの薪ストーブまで幅広くラインナップしています。</p>
              <p className="mt-8">ショールーム内に15台程度の薪ストーブを設置し、冬季は3台の薪ストーブに火を入れておりますので、実際に薪ストーブの暖かさを感じて頂くことができます。</p>
            </div>
            <div className="access mt-12">
              <h4 className="yu text-2xl c-gray">アクセス</h4>
              <div className="map md:flex mt-12">
                <div className="map-r md:w-1/2">
                  <div className="panel-showroom md:w-full">
                    <p className="name">北海道ショールーム</p>
                    <p>〒063-0812 札幌市西区琴似2条7丁目2-1<br />
                      TEL:011-624-5158   FAX:011-624-5159<br />
                      営業時間:9:00-17:30<br />
                      定休日：土曜日、日曜日、祝日 （※要確認）<br />
                      来店予約：不要<br />
                      ※土日にご来店をご希望される方は、お電話にてお問合せをお願い致します。</p>
                  </div>
                  <div className="map-l mt-10">
                    <div className="gmap">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2914.482550888691!2d141.2977004152749!3d43.07334939774951!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5f0b284a0dc14c3b%3A0x3f2bbb03f9a16162!2z44CSMDYzLTA4MTIg5YyX5rW36YGT5pyt5bmM5biC6KW_5Yy655C05Ly877yS5p2h77yX5LiB55uu77yS4oiS77yR!5e0!3m2!1sja!2sjp!4v1623056111850!5m2!1sja!2sjp" width="600" height="450" style={{border: 0}} loading="lazy" title="sapporo" />
                    </div>
                  </div>
                </div>
                <div className="figure-wrap sapporo md:w-1/2">
                  <div className="w-full figure">
                    <Image className="w-1/2 full-image" filename="showroom/figure-sapporo.png" alt="マップ 札幌" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-block" id="qa">
            <h3 className="sub-title">Q＆A<span>ご来場前にチェック！<br className="sp" />ショールームに関するご質問</span></h3>
            <div className="mt-8">
              <Accordion title="ショールームを体験するのにどれくらいの時間が必要ですか？">
                <p>
                  お時間の目安は『約1時間』です。<br />
                  また、より多くのお客様にご体感いただけるよう、一組様最大2時間までとさせていただいております。
                </p>
              </Accordion>
              <Accordion title="薪ストーブ料理を体験してみたいのですが、食材を持ちこんだりその場で調理することは可能ですか？">
                <p>
                  申し訳ございません。食品衛生上の観点および火傷等の事故防止の観点から、食材の持ち込みやお客様ご自身での料理はご遠慮いただいております。<br />
                  調理に使用するアクセサリや調理方法をご紹介させていただきます。
                </p>
              </Accordion>
              <Accordion title="ショールームまで車で行っても大丈夫ですか？">
                <p>
                  もちろん大丈夫です。当ショールームはお客様専用駐車場を完備しております。<br />
                  カーナビをご利用の方は住所に『千葉県松戸市稔台6-7-5』とご入力下さい。澁澤倉庫とロッテMary’sチョコレート工場の間の通路を150m程進んでいただくとショールームがございます。（通路の入口は新宮商行の赤茶色の看板が目印です。）<br />
                  門を入って左の駐車スペースをご利用ください。<br />
                  <Link className="c-attention" to="/showroom/">▶︎ 詳しくはこちら</Link>
                </p>
              </Accordion>
              <Accordion title="ショールームへ行くのに人数制限はありますか？両親も連れていきたいのですが…">
                <p>
                  業界最大級の広さを有しておりますので、人数制限は設けておりません。<br />
                  ぜひご家族皆様でお越しください。ご友人様とご一緒でも大歓迎です。
                </p>
              </Accordion>
              <Accordion title="ペットを一緒に連れて行っても大丈夫ですか？">
                <p>
                  申し訳ございません。当ショールームではペットをお連れでのご来場はご遠慮いただいております。
                </p>
              </Accordion>
              <Accordion title="予約は必要ですか？">
                <p>
                  他のお客様と被らないようご予約は必須とさせていただいております。下記予約フォームよりご予約をお願い致します。<br />
                  <Link className="c-attention" to="/reserve/">▶︎ ご予約はこちら</Link>
                </p>
              </Accordion>
              <Accordion title="土曜日曜は営業していますか？">
                <p>
                  3営業日前までにご予約いただいた場合、土曜・日曜もご来場していただけます。<br />
                  （祝祭日・GW・お盆・年末年始等を除く。）<br />
                  ただしご予約が入っていない場合は休業日になりますので、ショールームは開いておりません。必ずご予約のうえご来場ください。<br />
                  <Link className="c-attention" to="/reserve/">▶︎ ご予約はこちら</Link>
                </p>
              </Accordion>
              <Accordion title="まだ具体的な導入予定は無いですが、ショールームを見に行ってもいいですか？">
                <p>
                  もちろんです！私たちは皆様に「一番最初に薪ストーブを体感してみる」ことをお薦めしております。なぜなら、薪ストーブは火を焚いてこそ本来の魅力を知っていただけるものだからです。薪のはぜる音・炎の美しい揺らめき、陽だまりのような柔らかい暖かさ、そしてそこに流れる上質な時間･･･。これは言葉だけでは説明しがたく、薪ストーブを体感していただいて初めて納得していただけるものだと考えております。導入が未定の方もぜひ遊びにきて下さい♪
                </p>
              </Accordion>
            </div>
          </div>
          <div className="content" id="reserve">
            <Link to="/reserve/" className="btn full md:mt-16 mt-8 white">ショールームを予約する</Link>
          </div>
        </div>
        <div className="side-list">
        <ul className="sticky">
            <li>
              <GoLink to="matudo" spy smooth duration={800}>
                松戸ショールーム
              </GoLink>
            </li>
            <li>
              <GoLink to="sapporo" spy smooth duration={800}>
                北海道ショールーム
              </GoLink>
            </li>
            <li>
              <GoLink to="qa" spy smooth duration={800}>
                Q&A
              </GoLink>
            </li>
            <li>
              <GoLink to="reserve" spy smooth duration={800}>
                ご予約フォーム
              </GoLink>
            </li>
          </ul>
        </div>
      </section>

      <section className="bread">
        <ul>
          <li>
            <Link to="/">
              <Image filename="ico/home.png" />
            </Link>
          </li>
          <li>ショールームのご案内</li>
        </ul>
      </section>
    </Layout>
);

export default ShowroomPage;
